function decorateLinks(e) {
  const document = e.target.contentWindow.document;
  document.querySelectorAll('a').forEach((el) => {
    if (!el.target) {
      el.target = '_top';
    }
  });
}

function adjustFrameSize(iframe) {
  return () => {
    const { clientHeight } = iframe.contentWindow.document.body;
    iframe.style.height = `${clientHeight * 1.12}px`;
  };
}

function attachResizeListener(iframe, onResize) {
  function listenForContentChanges() {
    const observer = new MutationObserver(onResize);
    observer.observe(iframe.contentWindow.document.body, {childList: true, subtree: true});
  }

  iframe.addEventListener('load', onResize);
  iframe.addEventListener('load', listenForContentChanges);
  iframe.addEventListener('load', decorateLinks);
}

function makeFrame(content) {
  const iframe = document.createElement('iframe');
  iframe.className = `
    overflow-hidden
    h-96
    ml-auto
    mr-auto
    w-full
    max-w-screen-xl
    transition-height
    duration-200
    ease-in-out
  `;

  /*
    sandbox prevents script execution, form execution, popups, and more.

    allow-same-origin is required to allow the parent frame to access the iframe's contentWindow
    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#sandbox
  */
  iframe.sandbox.add('allow-same-origin');
  iframe.sandbox.add('allow-popups');
  iframe.sandbox.add('allow-popups-to-escape-sandbox');
  iframe.sandbox.add('allow-forms');
  iframe.sandbox.add('allow-top-navigation-by-user-activation');
  attachResizeListener(iframe, adjustFrameSize(iframe));
  iframe.srcdoc = content;

  return iframe;
}

document.addEventListener('DOMContentLoaded', () => {
  const mountpoint = document.querySelector('#embedded_content');

  const iframe = makeFrame(`
    ${mountpoint.dataset.stylesheets} 
    ${mountpoint.dataset.content}
 `);

  mountpoint.appendChild(iframe);
});
